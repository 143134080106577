<template>
    <v-layout column="" row wrap pr-3 mr-2 mt-1>
        <v-dialog v-model="dialog" width="850" persistent>
            <v-card>
                <v-form ref="form" v-model="valid">
                <v-card-title class="headline grey lighten-2" primary-title
                    style="	font-family: 'droid_bold' !important;    background-color: #435c6f!important;color:#fff">

                    <span v-if="editedIndex==1">تعديل اشتراك</span>
                    <span v-else>اضافه اشتراك</span>
                </v-card-title>

                <v-container>
                    <v-layout row wrap>
                        <v-flex pa-3 xs12>
                            <v-lable>اسم حزمه الاشتراك</v-lable>
                            <v-text-field filled background-color="#eeeeee" v-model="editedItem.package_name"
                                height="40" style="" required :rules="nameRules" placeholder="اسم حزمه الاشتراك" ></v-text-field>
                        </v-flex>

                        <v-flex pa-3 xs12>
                            <v-lable>عدد الحجوزات المسموحه</v-lable>
                            <v-text-field filled background-color="#eeeeee" v-model="editedItem.reservations_count"
                                height="40" style="" required type="number" :rules="reservations_countRules" placeholder="عدد الحجوزات المسموحه" ></v-text-field>
                        </v-flex>



                        <v-flex pa-3 xs12>
                            <v-lable>سعر الاشتراك</v-lable>
                            <v-text-field type="number" filled background-color="#eeeeee" :rules="PriceRules" v-model="editedItem.price_value" height="40"
                                style="" required placeholder="سعر الاشتراك" ></v-text-field>
                        </v-flex>
                    </v-layout>


                    <v-layout row wrap pr-5>


                        <v-btn @click="submit()" color="success" style="margin-left:10px" width="150px">
                            <span v-show="!loading">حفظ</span>
                            <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>
                        </v-btn>

                        <v-btn @click="close()" color="red" dark="" width="150px"> خروج</v-btn>

                    </v-layout>



                </v-container>
                </v-form>
            </v-card>
        </v-dialog>

        <v-row>

            <v-data-table :headers="headers" :items="subscriptions" sort-by="calories" class="elevation-1 request_table"
                style="width:96%" :items-per-page="25"  hide-default-footer="" :loading="loading"
                loading-text="جاري تحميل البيانات">


                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>
                        <v-toolbar-title>
                            اسعار الاشتراكات
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>


                        <v-spacer></v-spacer>
                        <v-row justify="end" style="margin-top:20px;">
                            <div style="width:auto">
                                <v-btn color="primary" dark class="mb-2" @click="dialog=true">
                                    <span style="margin-left:5px;font-family:'cairo_Semi'">اضافه تسعيره اشتراك</span>
                                    <v-icon size="15">fas fa-plus</v-icon>
                                </v-btn>
                            </div>
                        </v-row>


                    </v-toolbar>
                </template>






                <template v-slot:item.edit="{ item }">

                    <v-btn icon="" @click="editItem(item)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn>

                   

                </template>



            </v-data-table>

        </v-row>
        <v-row>


            <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle=""
                :length="pageCount">
            </v-pagination>
        </v-row>


    </v-layout>

</template>


<script>
    const axios = require('axios');
    export default {
        data: () => ({
            subscriptions: [],
            validate:false,
            page: 1,
            pageCount: 0,
            current_page: 1,
            last_page: 0,    
            editedIndex: -1,
            calander_dialog: false,
            err_show: false,
            dialog: false,

            nameRules: [
                (v) => !!v || 'يجب ادخال الاسم',

            ],
             reservations_countRules: [
                (v) => !!v || 'يجب ادخال عدد الحجوزات المسموحه',

            ],
            
             PriceRules: [
                (v) => !!v || 'يجب ادخال سعر الحزمه',

            ],

            loding_accept: false,
            loding_cancel: false,
            headers: [{
                    text: 'رقم التعريف',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'اسم الحزمه',
                    align: 'center',
                    sortable: false,
                    value: 'package_name',
                },

                {
                    text: 'عدد الحجوزات',
                    align: 'center',
                    sortable: false,
                    value: 'price_value',
                },

                {
                    text: 'سعر الحزمه',
                    align: 'center',
                    sortable: false,
                    value: 'price_value',
                },






                {
                    text: '',
                    value: 'edit',
                    sortable: false
                },


            ],


           
            loading: false,
            editedItem:{
                id:'',
                package_name:'',
                reservations_count:'',
                price_value:''
            }


        }),



        methods: {


            submit() {
                if (this.$refs.form.validate()) {


                    var data = {

                        package_name: this.editedItem.package_name,
                        reservations_count: this.editedItem.reservations_count,
                        price_value: this.editedItem.price_value,
                      


                    };
 var posturl ;
                    if (this.editedIndex > -1) {

                        posturl = "v2/SubscriptionPackages/operation/update/"+this.editedItem.id;
                    } else {

                        posturl = "v2/SubscriptionPackages/operation/set";


                    }

                     this.loading = true;
                    this.$http({
                            method: 'post',
                            url: posturl,
                            data: data,
                            headers: {

                            }

                        })
                        .then(response => {


                            if (this.editedIndex > -1) {
                                this.$swal("تم تعديل ", "", "success")



                            } else {
                                this.$swal("تم الاضافه", "", "success")


                            }
                            this.dialog = false;
                            this.loading = false;
                            this.$refs.form.reset();
                            this.getItems();

                            if (response.data.success == true) {
                                this.$refs.form.reset()
                                this.addlocation = false;


                            }
                            //



                        })
                        .catch(error => {
                            error
                        }).finally(d => {
                            d
                        });
                }

                },


  editItem(item) {
      this.dialog=true;
              
                this.editedItem = Object.assign({}, item)
                this.editedIndex = this.subscriptions.indexOf(item)



            },
                delete_item(id) {


                        this.user_info = this.$cookies.get('admin_info');
                        const Swal = require('sweetalert2')


                        Swal.fire({
                            title: "هل انت متاكد من الحذف ؟",

                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'نعم',
                            cancelButtonText: 'خروج'

                        }).then((result) => {
                            if (result.value) {
                                var url = "/operation/DeletedUser/" + id;
                                axios({
                                    method: 'delete',
                                    url: url,
                                    headers: {}

                                }).then(response => {
                                    response

                                    this.Search();


                                }).catch(error => {
                                    error
                                    // this.$swal('خطاء', "خطاء بالاتصال", 'error')
                                }).finally(d => {
                                    d,
                                    Swal.fire(
                                        'تم الحذف بنجاح',
                                        '',
                                        'success'
                                    )
                                    this.Search();
                                });



                            }
                        })



                    },

                    getItems() {
                        this.loading = true;
                        this.loading = true;
                        this.$http({
                            method: 'get',
                            url: '/v2/SubscriptionPackages/operation/get',
                            headers: {

                            }

                        }).then(response => {
                            this.loading = false;
                            this.subscriptions = response.data.data;
                        }).catch(error => {
                            error
                        }).finally(s => {
                            s,
                            this.loading = false;
                        });
                    },
                
                





                    close() {
                        this.dialog = false;
                        this.getItems();
                    },






                    getMoreitems() {

                        if (this.current_page <= this.last_page) {
                            this.current_page = this.page;
                            this.getItems();
                        }



                    },



                   

            },

            mounted() {
                this.getItems();
            },


            computed: {

                selected: function () {

                    return this.getMoreitems();
                }


            },


            watch: {
                selected: 'search by sub_cat_id',
            },

        }
</script>